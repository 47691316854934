import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './Marker.styles';

const useStyles = makeStyles(theme => styles(theme));

export default function Marker({ text }) {
  const classes = useStyles();

  return <div className={classes.Marker}>{ text }</div>;
}
