import pin from "../../images/map-pin.png";

export default theme => ({
    Marker: {
        width: 28,
        height: 39,
        color: theme.palette.common.white,
        fontSize: 16,
        fontFamily: theme.bodyFont,
        padding: 3,
        textAlign: 'center',
        backgroundImage: 'url('+pin+')',
    }
});