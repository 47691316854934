import pageTitle from "../../images/page-title.png";
import icon from "../../images/search-white.png";
import pin from "../../images/map-pin.png";
import buttonArrow from "../../images/button-arrow-white.png";

export default (theme) => ({
  stockistTitle: {
    position: "relative",
    marginBottom: 0,
    padding: "32px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "84px 0",
    },
    "& .heading": {
      flex: "0 0 100%",
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 56.25%",
        maxWidth: "56.25%",
      },
    },
    "& .search": {
      flex: "0 0 100%",
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 43.75%",
        maxWidth: "43.75%",
      },
    },
    "& .current-location": {
      padding: "16px 90px 0 40px",
      color: theme.palette.common.white,
      background: "transparent",
      border: 0,
      outline: "none",
      fontFamily: theme.bodyFont,
      textDecoration: "underline",
    },
    "& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
      marginBottom: 0,
      position: "relative",
      lineHeight: 1.2,
    },
    "&::before": {
      display: "block",
      width: "100vw",
      position: "absolute",
      zIndex: -1,
      content: '""',
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundImage: "url(" + pageTitle + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    "& form": {
      position: "relative",
      "& label": {
        position: "absolute",
        top: 12,
        left: 31,
        fontWeight: "bold",
        opacity: 0.5,
        textTransfrom: "uppercase",
        color: "white",
        textTransform: "uppercase",
        fontSize: 8,
        letterSpacing: "0.152em",
        lineHeight: 1.2,
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
          top: 16,
          left: 41,
        },
      },
      '& input[type="text"]': {
        border: "1px solid white",
        backgroundColor: "white",
        borderRadius: 28,
        padding: "28px 60px 10px 30px",
        display: "block",
        margin: 0,
        width: "100%",
        lineHeight: 1,
        fontSize: 12,
        color: theme.palette.primary.main,
        fontFamily: theme.bodyFont,
        letterSpacing: "0.032em",
        outline: "none",
        [theme.breakpoints.up("md")]: {
          padding: "32px 90px 16px 40px",
          fontSize: 16,
          borderRadius: 36,
        },
        "& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder": {
          color: "white",
          opacity: 1,
        },
      },
      '& input[type="submit"]': {
        position: "absolute",
        outline: "none",
        top: 11,
        right: 11,
        width: 32,
        height: 32,
        border: "none",
        backgroundColor: "transparent",
        borderRadius: "50%",
        fontSize: 0,
        backgroundPosition: "center",
        backgroundImage: "url(" + icon + ")",
        backgroundSize: "cover",
        [theme.breakpoints.up("md")]: {
          width: 49,
          height: 49,
        },
      },
    },
  },
  intro: {
    padding: "32px 0 20px",
    position: "relative",
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      padding: "50px 0 34px",
    },
    "&::before": {
      zIndex: -1,
      content: '""',
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      position: "absolute",
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.secondary.main + "0A",
    },
    "& > *": {
      marginBottom: 12,
      [theme.breakpoints.up("md")]: {
        marginBottom: 16,
      },
    },
  },
  results: {
    "& section": {
      [theme.breakpoints.up("md")]: {
        borderRight: "2px solid " + theme.palette.secondary.main + "0A",
      },
    },
    "& .city": {
      display: "block",
      fontSize: 24,
      marginTop: 5,
      fontWeight: 200,
      marginBottom: 24,
      [theme.breakpoints.up("md")]: {
        marginBottom: 32,
        fontSize: 32,
      },
    },
    "& .title": {
      fontSize: 16,
      fontFamily: theme.bodyFont,
      letterSpacing: "0.032em",
      lineHeight: 1.5,
      marginTop: 0,
      "& .results": {
        fontWeight: "bold",
      },
    },
    "& .result": {
      display: "flex",
      marginBottom: 24,
    },
    "& .result p": {
      margin: 0,
    },
    "& .pin": {
      paddingLeft: 6,
      paddingRight: 8,
      width: 28,
      height: 39,
      backgroundImage: "url(" + pin + ")",
      color: theme.palette.primary.contrastText,
      padding: 3,
      textAlign: "center",
      marginRight: 16,
    },
    "& .name": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textDecoration: "underline",
      fontSize: 18,
      letterSpacing: "0.008em",
      lineHeight: 1,
    },
    "& .distance": {
      color: "#555",
      fontSize: 16,
      lineHeight: 1.5,
      opacity: 0.7,
      letterSpacing: "0.032em",
      marginTop: 12,
      display: "block",
    },
  },
  imageLinkRow: {
    marginTop: 36,
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      marginTop: 80,
      marginBottom: 130,
    },
    "& a.image-link": {
      "&:after": {
        backgroundImage: "url(" + buttonArrow + ")",
      },
    },
  },
});
